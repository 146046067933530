<template>
  <div v-if="isLoadingCompanyList">
    <skeleton-dashboard></skeleton-dashboard>
  </div>
  <div v-else class="row">
    <div class="pt-3 pb-3">&nbsp;</div>
     <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="row" v-if="IsAdmin">
          <div class="col-sm-12 col-md-12 col-lg-12 form-group has-label pull-left mb-4">
                            <label>Select Campaign Type</label>
                                <el-select
                                    class="select-primary"
                                    size="large"
                                    placeholder="Select Client Campaign Type"
                                    filterable
                                    default-first-option
                                    v-model="selects.campaignTypeSelected"
                                    @change="onCampaignTypeChange(selects.campaignTypeSelected);"
                                >

                                    <el-option
                                        v-for="option in selects.campaignTypes"
                                        class="select-primary"
                                        :value="option.name"
                                        :label="option.name"
                                        :key="option.id"
                                        v-if="option.if"
                                    >
                                    </el-option>

                                </el-select>
              </div>
             <div class="col-sm-12 col-md-12 col-lg-12 form-group has-label pull-left">
                            <label>Select Client Campaign Name</label>
                                <el-select
                                    class="select-primary"
                                    size="large"
                                    placeholder="Select Client Campaign Name"
                                    filterable
                                    default-first-option
                                    v-model="selects.companySelected"
                                    @change="onCompanyChange(selects.companySelected);"
                                    @visible-change="onDropdownVisible"
                                    ref="select"
                                >
                                
                                    <el-option
                                        v-for="option in selects.companyList"
                                        class="select-primary"
                                        :value="option.id"
                                        :label="(option.leadspeek_api_id !='')?option.campaign_name + ' #' + option.leadspeek_api_id:option.campaign_name"
                                        :key="option.id"
                                    >
                                    </el-option>
                                    <el-option v-if="paginationGetClient.perPage < paginationGetClient.total && paginationGetClient.perPage > 8" value="loading" label="Loading...." disabled>
                                    </el-option>
                                </el-select>
              </div>
        </div>
     </div>
     <div v-if="isLoadingFilterCampaign" class="col-sm-12 col-md-12 col-lg-12">
        <skeleton-without-filter-campaign></skeleton-without-filter-campaign>
     </div>
     <div v-else class="col-sm-12 col-md-12 col-lg-12 pt-3 pb-3">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pt-3 pb-3">
           <div class="row">
             <!-- Stats Cards -->
               <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.id">
                 <stats-card class="text-title"
                   :title="card.title"
                   :sub-title="card.subTitle"
                   :type="card.type"
                   :icon="card.icon"
                 >
                   <div slot="footer" v-html="card.footer"></div>
                 </stats-card>
               </div>
           </div>
        </div>
   
             <div class="col-sm-12 col-md-12 col-lg-12 text-right">
               <div class="d-inline-block pr-2">Date Filter:</div>
               <div class="d-inline-block">
                   <base-input>
                     <el-date-picker
                       type="date"
                       placeholder="Date Start"
                       v-model="LeaddatePickerStart"
                       @change="GetLeadsData(1)"
                     >
                     </el-date-picker>
                   </base-input>        
               </div>
               <div class="d-inline-block pl-2 pr-2">-</div>
               <div class="d-inline-block">
                   <base-input>
                     <el-date-picker
                       type="date"
                       placeholder="Date End"
                       v-model="LeaddatePickerEnd"
                       @change="GetLeadsData(1)"
                     >
                     </el-date-picker>
                   </base-input>        
               </div>
             </div>
             <div class="col-sm-12 col-md-12 col-lg-12 text-right">
               <small>Last Updated : <span v-html="datelastleadadd"></span></small>
             </div>
           
           
           <div class="pt-3 pb-3">&nbsp;</div>
   
       <!-- Big Chart -->
       <div class="col-12">
         <card type="chart">
           <template slot="header">
             <div class="row">
               <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                 <h2 class="card-title">Performance</h2>
               </div>
               <div class="col-sm-6 d-flex d-sm-block">
                 <div
                   class="btn-group btn-group-toggle"
                   :class="isRTL ? 'float-left' : 'float-right'"
                   data-toggle="buttons"
                 >
                   <label
                     v-for="(option, index) in bigLineChartCategories"
                     :key="option.name"
                     class="btn btn-sm text-white"
                     :class="{ active: bigLineChart.activeIndex === index }"
                     :id="index"
                      @click="initBigChart(index)"
                   >
                     <input
                       type="radio"
                       name="options"
                       autocomplete="off"
                       :checked="bigLineChart.activeIndex === index"
                     />
                     <span class="d-none d-sm-block">{{ option.name }}</span>
                     <span class="d-block d-sm-none">
                       <i :class="option.icon"></i>
                     </span>
                   </label>
                 </div>
               </div>
             </div>
           </template>
           <div class="chart-area">
             <line-chart
               style="height: 100%"
               ref="bigChart"
               :chart-data="bigLineChart.chartData"
               :gradient-colors="bigLineChart.gradientColors"
               :gradient-stops="bigLineChart.gradientStops"
               :extra-options="bigLineChart.extraOptions"
             >
             </line-chart>
           </div>
         </card>
       </div>
       
       
       <div class="col-lg-12">
         <card class="card" :header-classes="{ 'text-right': isRTL }">
           <div slot="header">
             <h3 class="card-title d-inline">Contact Details</h3>
             <div class="d-inline float-right">
                <div class="d-inline-block">
                    <base-input>
                      <el-input class="mb-3 search-input input__client__management" @keyup.enter="GetLeadsData(1)" @keydown.enter.native="GetLeadsData(1)"
                        v-model="searchQuery" prefix-icon="el-icon-search" placeholder="Search" >
                      </el-input>
                    </base-input>        
                </div>
             </div>
           </div>
           
           <div class="table-responsive" style="overflow: hidden;">
             <lead-table :showaddress="show_address" :showphone="show_phone" :tableData="leadData" :pagination="pagination" :GetLeadsData="GetLeadsData" :tableNoData="tableNoData"></lead-table>
           </div>
           <div class="float-right" style="margin-top: 0.5rem;">
             <base-button size="sm" style="height:40px" @click="ExportLeadsData()">
               <i class="fas fa-cloud-download-alt pr-2"></i> Download Data
             </base-button>
           </div>
         </card>
       </div>
   
       <div class="col-lg-12" v-if="false">
         <card class="card" :header-classes="{ 'text-right': isRTL }">
           <div slot="header">
             <h3 class="card-title d-inline">Invoices</h3>
             <div class="d-inline float-right">
               <div class="d-inline-block pr-2">Date Filter:</div>
               <div class="d-inline-block">
                   <base-input>
                     <el-date-picker
                       type="date"
                       placeholder="Date Start"
                       v-model="InvdatePickerStart"
                       @change="GetInvoiceData()"
                     >
                     </el-date-picker>
                   </base-input>        
               </div>
               <div class="d-inline-block pl-2 pr-2">-</div>
               <div class="d-inline-block">
                   <base-input>
                     <el-date-picker
                       type="date"
                       placeholder="Date End"
                       v-model="InvdatePickerEnd"
                       @change="GetInvoiceData()"
                     >
                     </el-date-picker>
                   </base-input>        
               </div>
   
             </div>
           </div>
           
           <div class="table-responsive"><invoice-table :tableData="invoiceData"></invoice-table></div>
         </card>
       </div>
      </div>
     </div>

    <!--<div class="col-lg-12"><country-map-card></country-map-card></div>-->
  </div>
</template>
<script>
import LineChart from 'src/components/Charts/LineChart';
import * as chartConfigs from 'src/components/Charts/config';
import LeadTable from 'src/pages/Dashboard/LeadsTable';
import InvoiceTable from 'src/pages/Dashboard/LeadsInvoice';
import StatsCard from 'src/components/Cards/StatsCard';
import config from 'src/config';
import { DatePicker, Select, Option } from 'element-ui';
import { SkeletonDashboard, SkeletonWithoutFilterCampaign } from 'src/components/Pages/Dashboard'
import moment from 'moment'
var CHECK_GROUPCOMPANY;

let nowdate = new Date();
let currMonth = nowdate.getMonth();

let bigChartData = []
let monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
var monthList = [];
var monthTotal = [];
/*for(var i=0;i<=currMonth;i++) {
  monthList[i] = monthNames[i];
}*/
let bigChartLabels = [
  monthList,
  ['MON','TUE','WED','THU','FRI','SAT','SUN'],
]

let bigChartDatasetOptions = {
  fill: true,
  borderColor: config.colors.primary,
  borderWidth: 2,
  borderDash: [],
  borderDashOffset: 0.0,
  pointBackgroundColor: config.colors.primary,
  pointBorderColor: 'rgba(255,255,255,0)',
  pointHoverBackgroundColor: config.colors.primary,
  pointBorderWidth: 20,
  pointHoverRadius: 4,
  pointHoverBorderWidth: 15,
  pointRadius: 4,
}

export default {
  components: {
    [DatePicker.name]: DatePicker,
    LineChart,
    StatsCard,
    LeadTable,
    InvoiceTable,
    [Option.name]: Option,
    [Select.name]: Select,
    SkeletonDashboard,
    SkeletonWithoutFilterCampaign
  },
  data() {
    return {
      companyID:'',
      ClientActiveID: '',
      IsAdmin: false,
      datelastleadadd: '',
      searchQuery: '',
      leadData:[],
      invoiceData:[],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0,
        from: 0,
        to: 0,
      },
      paginationGetClient: {
        perPage: 10,
        currentPage: 1,
        total: 0,
      },
      selects: {
        companySelected: '',
        companyList: [],
        campaignTypeSelected: this.$global.globalModulNameLink.local.name,
        campaignTypes: [
          {
          id:'site-id',
          name: this.$global.globalModulNameLink.local.name,
          path: '/' + this.$global.globalModulNameLink.local.url + '/dashboard',
          if:(this.$global.menuLeadsPeek && this.$global.menuUserType != 'client' && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected && this.checkPermissionMenu('local')) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_type == 'local' && this.$global.menuUserType == 'client' && this.$global.disabledaddcampaign && this.checkPermissionMenu('local'))
        },
        {
          id:'search-id',
          name: this.$global.globalModulNameLink.locator.name,
          path:  '/' + this.$global.globalModulNameLink.locator.url + '/dashboard',
          if:(this.$global.menuLeadsPeek && this.$global.menuUserType != 'client'  && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected && this.checkPermissionMenu('locator')) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_typeLocator == 'locator' && this.$global.menuUserType == 'client' && this.$global.disabledaddcampaign && this.checkPermissionMenu('locator'))
        },
        {
          id:'enhance-id',
          name: this.$global.globalModulNameLink.enhance.name,
          path:  '/' + this.$global.globalModulNameLink.enhance.url + '/dashboard',
          if:(this.$global.menuLeadsPeek && this.$global.menuUserType != 'client'  && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected && this.checkPermissionMenu('enhance')) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_typeLocator == 'locator' && this.$global.menuUserType == 'client' && this.$global.disabledaddcampaign && this.checkPermissionMenu('enhance'))
        },
        {
          id:'b2b-id',
          name: this.$global.globalModulNameLink.b2b.name,
          path:  '/' + this.$global.globalModulNameLink.b2b.url + '/dashboard',
          // if:(this.$global.menuLeadsPeek && this.$global.menuUserType != 'client'  && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected && this.checkPermissionMenu('b2b')) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_typeLocator == 'locator' && this.$global.menuUserType == 'client' && this.$global.disabledaddcampaign && this.checkPermissionMenu('b2b'))
          if:((this.$global.menuLeadsPeek && this.$global.menuUserType != 'client'  && !this.$global.systemUser && this.$global.creditcardsetup && this.$global.stripeaccountconnected && this.checkPermissionMenu('b2b')) || (this.$global.menuLeadsPeek && this.$global.menuLeadsPeek_typeLocator == 'locator' && this.$global.menuUserType == 'client' && this.$global.disabledaddcampaign && this.checkPermissionMenu('b2b'))) && (!this.$global.betaFeature.b2b_module.is_beta || this.$global.betaFeature.b2b_module.apply_to_all_agency || this.$global.isBeta)
        },
      ],
      },

      firstDayDate: '',
      lastDayDate: '',
      LeaddatePickerStart: '',
      LeaddatePickerEnd: '',
      InvdatePickerStart: '',
      InvdatePickerEnd: '',
      show_phone:false,
      show_address: false,

      statsCards: [
        {
          id: '1',
          title: '0 Contact(s)',
          subTitle: 'Total contacts',
          type: 'primary',
          icon: 'fas fa-sort-amount-up',
          footer: 'Lifetime total contacts'
        },
        {
          id: '2',
          title: '$0',
          subTitle: 'Total Cost',
          type: 'primary',
          icon: 'fas fa-dollar-sign',
          footer: 'Lifetime total contacts cost'
        },
        {
          id: '3',
          title: '0 Contact(s)',
          subTitle: 'Total contacts',
          type: 'primary',
          icon: 'fas fa-sort-amount-up',
          footer: 'Total contacts since last billing'
        },
        
        {
          id: '4',
          title: '$0',
          subTitle: 'Total Cost',
          type: 'primary',
          icon: 'fas fa-dollar-sign',
          footer: 'Total contacts cost since last billing'
        }
      ],

      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [{
            ...bigChartDatasetOptions,
            data: bigChartData[0]
          }],
          labels: bigChartLabels[0]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      tableNoData: false,
      isLoadingCompanyList: false,
      isLoadingFilterCampaign: false
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      //return [{ name: 'Monthly', icon: 'fas fa-calendar-alt' }, { name: 'Weekly', icon: 'fas fa-calendar-week' }];
      return [{ name: 'Monthly', icon: 'fas fa-calendar-alt' }];
    }
  },
  methods: {
    onCampaignTypeChange(name){
      const selectedCampaignTypePath = this.selects.campaignTypes.find(item => item.name === name).path
      this.$router.push({path:selectedCampaignTypePath})
    },
    async onCompanyChange(clientSelected) {
      this.isLoadingFilterCampaign = true
      //console.log(clientSelected);
      this.ClientActiveID = clientSelected;
      await this.GetClientList(true);
      this.isLoadingFilterCampaign = false
    },
    format_date(valdate,convert = false,toClientTime = false){
        if (valdate) {

            if (convert) {
                // Set the source and target timezones
                const sourceTimezone = this.$global.clientTimezone; 
                const targetTimezone = this.$global.systemTimezone;
                if (toClientTime) {
                    const sourceTimezone = this.$global.systemTimezone; 
                    const targetTimezone = this.$global.clientTimezone; 
                }

                // Parse the input time in the source timezone
                const sourceMoment = this.$moment.tz(valdate, sourceTimezone);

                // Convert the time to the target timezone
                const targetMoment = sourceMoment.clone().tz(targetTimezone);

                return targetMoment.format('YYYY-MM-DD HH:mm:ss');
            }else{

                return this.$moment(valdate).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    },
    initBigChart(index) {
      //console.log('chart:' + index);
      let chartData = {
        datasets: [{
          ...bigChartDatasetOptions,
          data: bigChartData[index]
        }],
        labels: bigChartLabels[index]
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    getInitDate() {
        this.$store.dispatch('GetLeadsPeekInitDate', {
            companyID: this.companyID,
            clientID: this.ClientActiveID, 
        }).then(response => {
          //console.log(response);
          if (response.initdate.length > 0) {
            this.LeaddatePickerStart = response.initdate[0]['year'] + '-' + response.initdate[0]['month'] + '-01'
          }
          this.getTotalLeadsChart();
          this.GetLeadsData();
        },error => {
            
        });
    },
    getTotalLeadsChart() {
      var leadsReportStart = this.format_date(this.$moment(this.LeaddatePickerStart).format('YYYY-MM-DD') + " 00:00:00",true,false);
      var leadsReportEnd = this.format_date(this.$moment(this.LeaddatePickerEnd).format('YYYY-MM-DD') + " 23:59:59",true,false);
      
      /** GET CHART NUMBER */
      this.$store.dispatch('GetLeadsPeekChartReport', {
            companyID: this.companyID,
            clientID: this.ClientActiveID,
            startDate: leadsReportStart,
            endDate: leadsReportEnd,
        }).then(response => {
          //console.log(response.chartdataMonthly);
          monthList = [];
          monthTotal = [];
          bigChartLabels = [
            monthList,
            ['MON','TUE','WED','THU','FRI','SAT','SUN'],
          ]

          var tmp = 0;
          for(var i=0;i<response.chartdataMonthly.length;i++) {
            if (i == 0) {
              tmp = response.chartdataMonthly[i]['month'];
              if (tmp == 12) {
                tmp = 0;
              } 
              //console.log(tmp);
            }else{
              //console.log( response.chartdataMonthly[i]['month'] + ' | ' + tmp);
              var gap = response.chartdataMonthly[i]['month'] - tmp;
              if (gap > 1) {
                for(var k=tmp;k<(response.chartdataMonthly[i]['month']-1);k++) {
                  //console.log(k + ' | ' + response.chartdataMonthly[i]['month'] + ' | ' + monthNames[k]);
                  monthList.push(monthNames[k] + ' ' + response.chartdataMonthly[i]['year']);
                  monthTotal.push(0);
                }
                //console.log(gap + ' | ' + response.chartdataMonthly[i]['month'] + ' | ' + tmp);
                tmp = response.chartdataMonthly[i]['month'];
              }else{
                tmp = response.chartdataMonthly[i]['month'];
              }
            }

            

            //monthList[i] = monthNames[response.chartdataMonthly[i]['month']-1] + ' ' + response.chartdataMonthly[i]['year'];
            //monthTotal[i] = response.chartdataMonthly[i]['total'];
            monthList.push(monthNames[response.chartdataMonthly[i]['month']-1] + ' ' + response.chartdataMonthly[i]['year']);
            monthTotal.push(response.chartdataMonthly[i]['total']);
            //console.log(response.chartdataMonthly[i]['month']);
          }
          bigChartData = [];
          bigChartData.push(monthTotal);
         //bigChartData.push(response.chartdataWeekly);
          this.initBigChart(0);
        },error => {
            
        });
      /** GET CHART NUMBER */
    },
    ExportLeadsData() {
      var leadsExportStart = this.format_date(this.$moment(this.LeaddatePickerStart).format('YYYY-MM-DD') + " 00:00:00",true,false);
      var leadsExportEnd = this.format_date(this.$moment(this.LeaddatePickerEnd).format('YYYY-MM-DD') + " 23:59:59",true,false);
      var searchQuery = this.searchQuery;

      if(this.LeaddatePickerStart != '' && this.LeaddatePickerEnd != '' && this.ClientActiveID != '') {
        //window.open(process.env.VUE_APP_DATASERVER_URL + '/leadspeek/report/lead/export/' + this.companyID + '/' + this.ClientActiveID + '/' + this.LeaddatePickerStart + '/' + this.LeaddatePickerEnd, "_blank");

        //// yang lama ga dipake, pake yang baru menggunakan params jadi satu
        // document.location = process.env.VUE_APP_DATASERVER_URL + '/leadspeek/report/lead/export/' + this.companyID + '/' + this.ClientActiveID + '/' + leadsExportStart + '/' + leadsExportEnd;
        const params = new URLSearchParams({
          companyID: this.companyID,
          clientID: this.ClientActiveID,
          startDate: leadsExportStart,
          endDate: leadsExportEnd,
          searchQuery: searchQuery
        }).toString();
        document.location = process.env.VUE_APP_DATASERVER_URL + '/leadspeek/report/lead/export/?' + params ;
      }
    },
    GetLeadsData(currentPage, sortby, order) {
      let _sortby = sortby ? sortby : 'clickdate'
      let _order = order ? order : 'descending'
      if(currentPage){
          this.pagination.currentPage = currentPage
        }

        /** CLEAR DATA FIRST */
        this.leadData = [];
        /** CLEAR DATA FIRST */

        /** GET LEAD LIST */
        var leadsReportStart = this.format_date(this.$moment(this.LeaddatePickerStart).format('YYYY-MM-DD') + " 00:00:00",true,false);
        var leadsReportEnd = this.format_date(this.$moment(this.LeaddatePickerEnd).format('YYYY-MM-DD') + " 23:59:59",true,false);
        var searchQuery = this.searchQuery;

        if(this.LeaddatePickerStart != '' && this.LeaddatePickerEnd != '' && this.ClientActiveID != '') {
        this.$store.dispatch('GetLeadsPeekLeadReport', {
            companyID: this.companyID,
            clientID: this.ClientActiveID,
            startDate: leadsReportStart,
            endDate: leadsReportEnd,
            searchQuery: searchQuery,
            page: this.pagination.currentPage,
            sortby: _sortby,
            order: _order,
        }).then(response => {
            if (response.leads.data.length != 0) {
              this.leadData = response.leads.data;
              this.pagination.currentPage = response.leads.current_page? response.leads.current_page : 1
              this.pagination.total = response.leads.total ? response.leads.total : 0
              this.pagination.from = response.leads.from ? response.leads.from : 0
              this.pagination.to = response.leads.to ? response.leads.to : 0
            }else{
              $('.el-table__empty-text').html('No Data');
              this.leadData = [];
              this.pagination.currentPage = 1
              this.pagination.total = 0
              this.pagination.from = 0
              this.pagination.to = 0
            }
            this.getTotalLeadsChart();
        },error => {
            
        });
        }
        /** GET LEAD LIST */
    },
    GetInvoiceData() {
        /** GET LEAD LIST */
        var InvReportStart = this.format_date(this.$moment(this.InvdatePickerStart).format('YYYY-MM-DD') + " 00:00:00",true,false);
        var InvReportEnd = this.format_date(this.$moment(this.InvdatePickerEnd).format('YYYY-MM-DD') + " 23:59:59",true,false);

        if(this.InvdatePickerStart != '' && this.InvdatePickerEnd != '' && this.ClientActiveID != '') {
        this.$store.dispatch('GetLeadsPeekInvReport', {
            companyID: this.companyID,
            clientID: this.ClientActiveID,
            startDate: InvReportStart,
            endDate: InvReportEnd,
        }).then(response => {
            if (response.invoices.length != 0) {
              this.invoiceData = response.invoices;
            }
           //console.log(response.invoices);
        },error => {
            
        });
        }
        /** GET LEAD LIST */
    },
   async GetClientList(isFilter, isScroll) {
        if(isFilter){
          this.isLoadingCompanyList = false
        } else {
          this.isLoadingCompanyList = true
        }

        const clientId = isScroll ? '' : this.ClientActiveID

        var _groupCompanyID = '';
        if ((localStorage.getItem('companyGroupSelected') != null && localStorage.getItem('companyGroupSelected') != '')){
            _groupCompanyID = localStorage.getItem('companyGroupSelected');
        }
        //console.log(this.companyID);
        //console.log(this.ClientActiveID);
        //console.log(_groupCompanyID);
        /** GET CLIENT LIST */
       await this.$store.dispatch('GetLeadsPeekClientList', {
            companyID: this.companyID,
            clientID: clientId,
            leadspeekType: 'local',
            groupCompanyID: _groupCompanyID,
            page: this.paginationGetClient.currentPage,
            perPage: this.paginationGetClient.perPage,
            view: 'dashboard',
        }).then(response => {
            if (response.data.length == 0) {
                this.isLoadingCompanyList = false;
                this.tableNoData = true
                return false;
            }
            if (response.data[0].phoneenabled == 'T') {
              this.show_phone = true;
            }else{
              this.show_phone = false;
            }

            if (response.data[0].homeaddressenabled == 'T') {
              this.show_address = true;
            }else{
              this.show_address = false;
            }

            if (clientId == '') {
                this.selects.companyList = response.data;
                this.selects.companySelected = response.data[0].id;
                this.ClientActiveID = response.data[0].id;
            }

            this.paginationGetClient.total = response.total

            // Stats Card
            const lifetime_total_leads = response.data[0].lifetime_total_leads != 0 ? String(response.data[0].lifetime_total_leads) : `0 Contact(s)`
            const lifetime_total_leads_cost = this.$store.getters.userData.user_type == 'userdownline' ? this.$global.formatCurrency('', response.data[0].agency_lifetime_total_leads_cost) : this.$global.formatCurrency('', response.data[0].client_lifetime_total_leads_cost)
            const total_leads_last_billing = response.data[0].total_leads_last_billing != 0 ? String(response.data[0].total_leads_last_billing) : `0 Contact(s)`
            const total_cost_since_last_billing = this.$store.getters.userData.user_type == 'userdownline' ? this.$global.formatCurrency('', response.data[0].agency_total_cost_since_last_billing) : this.$global.formatCurrency('', response.data[0].client_total_cost_since_last_billing)

            this.statsCards[0].title = lifetime_total_leads
            this.statsCards[1].title = lifetime_total_leads_cost
            this.statsCards[2].title = total_leads_last_billing
            this.statsCards[3].title = total_cost_since_last_billing
            // Stats Card

            // var minLeads = (response[0].lp_max_lead_month != null)?response[0].lp_max_lead_month:0;
            // var minCostLeads = (response[0].lp_min_cost_month != null)?response[0].lp_min_cost_month:0;
            // var exceedLeads = 0;

            //var lifetimetotal = response.data[0].total_leads * response.data[0].cost_perlead;
            //var ongoingtotal = response.data[0].ongoing_leads * response.data[0].cost_perlead;

            // var lifetimetotal = (response[0].lifetime_cost != null)?response[0].lifetime_cost:0;
            
            // if(response[0].ongoing_lead > minLeads) {
            //   exceedLeads = response[0].ongoing_lead - minLeads;
            // }

            // var ongoingtotal = (response[0].cost_perlead * exceedLeads) + minCostLeads;

            //console.log(response[0].cost_perlead);
            // this.statsCards[0].title = (response[0].total_leads != null)?response[0].total_leads.toString():'0' + ' Lead(s)';
            // if (lifetimetotal != 0) {
            //   this.statsCards[1].title = this.$global.formatCurrency('',lifetimetotal);
            // }else{
            //   this.statsCards[1].title = '$' + lifetimetotal;
            // }
            // this.statsCards[2].title = (response[0].ongoing_leads != null)?response[0].ongoing_leads.toString():'0' + ' Lead(s)';
            // if (ongoingtotal != 0) {
            //   this.statsCards[3].title = this.$global.formatCurrency('',ongoingtotal);
            // }else{
            //   this.statsCards[3].title = '$' + ongoingtotal;
            // }

            this.datelastleadadd = (response.data[0].last_lead_added != null && response.data[0].last_lead_added != '')?response.data[0].last_lead_added:'00-00-0000';
            //this.LeaddatePickerStart = this.format_date(this.firstDayDate);
            //this.InvdatePickerStart = this.format_date(this.firstDayDate);
            //this.LeaddatePickerEnd = this.format_date(this.lastDayDate);
            //this.InvdatePickerEnd = this.format_date(this.lastDayDate);

            if(!isScroll){
              this.getInitDate();
            }
            //this.getTotalLeadsChart();
            //this.GetLeadsData();
            //this.GetInvoiceData();
           
                /*this.ClientData = response.data[0];
                this.radios.reportType = this.ClientData.report_type;
                this.reportSentTo = this.ClientData.report_sent_to;
                this.selects.frequencySelected = this.ClientData.report_frequency_id;
                this.LeadspeekID = this.ClientData.id;
                */
            //console.log(this.LeadspeekID);
            this.isLoadingCompanyList = false
            this.tableNoData = false
        },error => {
            
        });
        /** GET CLIENT LIST */
    },
    onDropdownVisible(visible) {
      if (visible) {
        this.$nextTick(() => {
          const dropdown = this.$refs.select.$refs.popper.$el.querySelector('.el-select-dropdown__wrap');
          if (dropdown) {
            dropdown.addEventListener('scroll', this.handleScroll);
          } else {
            console.error('Dropdown not found');
          }
        });
      }
    },
    handleScroll(event) {
      const dropdown = event.target;
      if (dropdown.scrollTop + dropdown.clientHeight >= dropdown.scrollHeight - 1) {
        if(this.paginationGetClient.perPage >= this.paginationGetClient.total){
          return;
        } else {
          this.paginationGetClient.perPage = this.paginationGetClient.perPage + 10
          this.GetClientList(true, true)
        }
      }
    },
    checkPermissionMenu(menuType){
      const userData = this.$store.getters.userData;

      if(userData.user_type == 'client'){
        const sidebar = this.$global.clientsidebar

        // console.log({
        //   'action': 'client',
        //   'this.$global.clientsidebar': this.$global.clientsidebar,
        //   'menuType': menuType,
        //   'sidebar': sidebar,
        // });

        const checkMenu = sidebar[menuType]
        
        return checkMenu
      } else if (userData.user_type == 'userdownline'){
        const sidebarAgency = this.$global.agency_side_menu && this.$global.agency_side_menu.find(menu => menu.type == menuType)
        
        // console.log({
        //   'action': 'userdownline',
        //   'this.$global.agency_side_menu': this.$global.agency_side_menu,
        //   'menuType': menuType,
        //   'sidebarAgency': sidebarAgency,
        //   'check_sidebarAgency': typeof(sidebarAgency) != "undefined",
        // });

        return (typeof(sidebarAgency) != "undefined") ? sidebarAgency.status : false;
      } else {
        return true
      }
    }
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'en';
      this.$rtl.enableRTL();
    }

    const userData = this.$store.getters.userData;
    const protectAccessMenu = () => {
      if(userData.user_type == 'client'){
        const sidebar = this.$global.clientsidebar

        if (!sidebar['local']){
          this.$router.push({ name: 'Profile Setup' });
        }
          
      } else if (userData.user_type == 'userdownline'){
          const sidebarAgency = this.$global.agency_side_menu && this.$global.agency_side_menu.find(menu => menu.type == 'local')

          if(sidebarAgency && !sidebarAgency.status){
              this.$router.push({ name: 'Profile Setup' });
          }
      }
    }

    protectAccessMenu()

    this.companyID = userData.company_id;
    this.ClientActiveID = '';
    //console.log(userData);
    if(userData.user_type == "client") {
        //this.ClientActiveID = userData.id;
        localStorage.setItem('companyGroupSelected',userData.id);
        this.companyID = userData.company_parent;
        this.IsAdmin = true;
    }else{
        this.IsAdmin = true;
    }
    // Get the first date of the current month
    const firstDate = this.$moment().startOf('month');

    // Get the last date of the current month
    const lastDate = this.$moment().endOf('month');

    this.firstDayDate = firstDate.format('YYYY-MM-DD');
    this.lastDayDate = lastDate.format('YYYY-MM-DD');

    //this.firstDayDate = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate();
    //this.lastDayDate = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate();
    
    this.LeaddatePickerStart = this.firstDayDate;
    this.InvdatePickerStart = this.firstDayDate;
    this.LeaddatePickerEnd = this.lastDayDate;
    this.InvdatePickerEnd = this.lastDayDate;

    //console.log(this.LeaddatePickerEnd);
    CHECK_GROUPCOMPANY = setInterval(() => {
            if ((localStorage.getItem('companyGroupSelected') != null)){
                clearInterval(CHECK_GROUPCOMPANY);
                this.GetClientList();
            }
        });
    
    //this.getTotalLeadsChart();

    //this.initBigChart(0);
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }

    const dropdown = this.$refs.select.$refs.popper.$el.querySelector('.el-select-dropdown__wrap');
    if (dropdown) {
      dropdown.removeEventListener('scroll', this.handleScroll);
    }
  }
};
</script>
<style>

</style>
